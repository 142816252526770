import { Component, Vue, Watch } from 'vue-property-decorator';

import HomePage from '@/views/home.page/index.vue';
import AboutQy from '@/views/about.qy/index.vue';
import ProductCenter from '@/views/product-center/index.vue';
import News from '@/views/news/index.vue';
import Cooperation from '@/views/cooperation/index.vue';
import {UserModule} from "@/store/modules/user";
// 页面
@Component({ name: 'home', components: { HomePage, AboutQy, ProductCenter, News, Cooperation } })
export default class extends Vue {
    public activeName = 'home' || 'gyqy' || 'cpzx' || 'xwzx' || 'hzgy'
    // 切换合作共赢tab 清空
    public changeTab(tab: any, event: any) {
        UserModule.SET_CooperationName(null)
    }
    // 跳转关于旗鱼页面
    public goAbutQy(name: string) {
        this.activeName = 'gyqy'
        this.$nextTick(() => {
            document.querySelector(`#${name}`).scrollIntoView(true)
        })
    }
    // 跳转頂部
    public toTop() {
        document.querySelector(`#top`).scrollIntoView(true)
    }
    // 跳转产品中心页面
    public goProductCenter(name: string) {
        this.activeName = 'cpzx'
        this.$nextTick(() => {
            document.querySelector(`#${name}`).scrollIntoView(true)
        })
    }
    // 合作共赢页面
    public goHZGY(name: string) {
        UserModule.SET_CooperationName(null)
        this.activeName = 'hzgy'
        this.$nextTick(() => {
            document.querySelector(`#${name}`).scrollIntoView(true)
        })
    }
    // 合作共赢中合作方式页面
    public goHZFS(name: string) {
        this.activeName = 'hzgy'
        UserModule.SET_CooperationName(name)
        this.$nextTick(() => {
            document.querySelector(`#top`).scrollIntoView(true)
        })
    }
    created() {
        this.activeName = 'home'
    }
}
