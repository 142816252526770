import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9518dad8&scoped=true&"
import script from "./cooperation.ts?vue&type=script&lang=ts&"
export * from "./cooperation.ts?vue&type=script&lang=ts&"
import style0 from "./style.scss?vue&type=style&index=0&id=9518dad8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9518dad8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workspace\\qycc-website\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9518dad8')) {
      api.createRecord('9518dad8', component.options)
    } else {
      api.reload('9518dad8', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=9518dad8&scoped=true&", function () {
      api.rerender('9518dad8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cooperation/index.vue"
export default component.exports