var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "el-carousel",
        {
          staticClass: "carousel-title",
          attrs: { interval: 3000, arrow: "never" },
        },
        [
          _c("el-carousel-item", [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("@/assets/page/sm.jpg") },
            }),
          ]),
          _c("el-carousel-item", [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("@/assets/page/jzg.jpg") },
            }),
          ]),
          _c("el-carousel-item", [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("@/assets/page/yj.jpg") },
            }),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "about-qy" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 关于旗鱼 ")]),
        _c("div", { staticClass: "line" }),
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "more-btn" }, [
          _c("div", { staticClass: "btn", on: { click: _vm.goToAboutQy } }, [
            _vm._v(" 查看更多 "),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "intelligent-platform" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(" 智慧平台 ")]),
          _c("div", { staticClass: "line" }),
          _c(
            "el-carousel",
            { attrs: { interval: 3000, arrow: "always" } },
            [
              _c("el-carousel-item", [
                _c("div", { staticClass: "carousel-item" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "474px",
                        "max-height": "318px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          display: "inline-block",
                        },
                        attrs: { src: require("@/assets/page/zhpt1.png") },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 用户端应用 "),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                        _vm._v(
                          "        用户端应用可以方便用户快速的进行充电，此外"
                        ),
                      ]),
                      _c("br"),
                      _vm._v(" 还支持账单管理和提现，随时查看设备状态，安心充"),
                      _c("br"),
                      _vm._v(" 电，充满自停等功能。 "),
                    ]),
                    _c("div", { staticClass: "icon" }, [
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticClass: "svg-icon",
                              attrs: { name: "zhpt-one", original: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "zhpt-icon-text" }, [
                          _vm._v(" 快速充电 "),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticClass: "svg-icon",
                              attrs: { name: "zhpt-two", original: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "zhpt-icon-text" }, [
                          _vm._v(" 远程操控 "),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticClass: "svg-icon",
                              attrs: { name: "zhpt-three", original: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "zhpt-icon-text" }, [
                          _vm._v(" 自动定位 "),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticClass: "svg-icon",
                              attrs: { name: "zhpt-four", original: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "zhpt-icon-text" }, [
                          _vm._v(" 安全保障 "),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-carousel-item", [
                _c("div", { staticClass: "carousel-item" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "474px",
                        "max-height": "318px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          display: "inline-block",
                          "margin-top": "50px",
                        },
                        attrs: { src: require("@/assets/page/kshdp.png") },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      staticStyle: { "margin-left": "88px" },
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 可视化大屏 "),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _c(
                          "span",
                          { staticStyle: { "white-space": "nowrap" } },
                          [
                            _vm._v(
                              "        数据可视化大屏可实时查看数据热点，直观动态，展"
                            ),
                          ]
                        ),
                        _c("br"),
                        _vm._v(
                          " 现业务数据及动态趋势，及时洞察消费市场及消费习惯，"
                        ),
                        _c("br"),
                        _vm._v(" 真正实现数据化监管。 "),
                      ]),
                      _c("div", { staticClass: "icon" }, [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "sjtb", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 实时同步 "),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "sjzg", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 数据直观 "),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "dtxs", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 动态显示 "),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "dyfx", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 多元分析 "),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("el-carousel-item", [
                _c("div", { staticClass: "carousel-item" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "474px",
                        "max-height": "318px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          display: "inline-block",
                          "margin-top": "10px",
                        },
                        attrs: { src: require("@/assets/page/yyglht.png") },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      staticStyle: { "margin-left": "88px" },
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 运营管理后台 "),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _c(
                          "span",
                          { staticStyle: { "white-space": "nowrap" } },
                          [
                            _vm._v(
                              "        全力打造专业的运营管理后台，同时也是 行业领先的"
                            ),
                          ]
                        ),
                        _c("br"),
                        _vm._v(
                          " 物联网大数据平台，形成政府、物业、运营商全生态闭环"
                        ),
                        _c("br"),
                        _vm._v(" 智能化物联网管理体系。 "),
                      ]),
                      _c("div", { staticClass: "icon" }, [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "cwgl", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 财务管理 "),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "sbgl", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 设备管理 "),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "yygl", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 运营管理 "),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("svg-icon", {
                                staticClass: "svg-icon",
                                attrs: { name: "ddgl", original: "" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "zhpt-icon-text" }, [
                            _vm._v(" 订单管理 "),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(2),
      _vm._m(3),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _vm._v(
        " 旗鱼充充立足于南昌，辐射全国，依托强大的产品研发与运营服务能力，已在全国200多个城市"
      ),
      _c("br"),
      _vm._v(
        " 完成市场布局，通过体系化运营服务持续赋能合作伙伴，为深耕全国市场打下了坚实的基础。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "number" }, [
      _c("span", { staticClass: "number-text1" }, [
        _vm._v(" 200 "),
        _c(
          "span",
          { staticStyle: { "font-size": "60px", "margin-left": "-24px" } },
          [_vm._v(" + ")]
        ),
      ]),
      _c("span", { staticClass: "text1" }, [_vm._v(" 覆 盖 城 市 ")]),
      _c("span", { staticClass: "number-text2" }, [
        _vm._v(" 2500 "),
        _c(
          "span",
          { staticStyle: { "font-size": "60px", "margin-left": "-24px" } },
          [_vm._v(" + ")]
        ),
      ]),
      _c("span", { staticClass: "text2" }, [_vm._v(" 运 营 网 点 ")]),
      _c("span", { staticClass: "number-text1" }, [
        _vm._v(" 50W "),
        _c(
          "span",
          { staticStyle: { "font-size": "60px", "margin-left": "-24px" } },
          [_vm._v(" + ")]
        ),
      ]),
      _c("span", { staticClass: "text3" }, [_vm._v(" 充 电 次 数 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "product-center" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 产品中心 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "imgs" }, [
        _c(
          "div",
          {
            staticClass: "QYCC-01",
            staticStyle: { width: "420px", height: "500px" },
          },
          [
            _c("img", {
              staticClass: "img QYCC-01",
              attrs: { src: require("@/assets/page/QYCC-01.png") },
            }),
            _c("div", { staticClass: "QYCC-01-hover-text" }, [
              _c("div", [_vm._v(" 电动汽车直流充电桩 ")]),
              _vm._v(" QY01-Y750/160C "),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "QYCC-7K",
            staticStyle: {
              width: "235px",
              height: "500px",
              "margin-left": "10px",
            },
          },
          [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("@/assets/page/QYCC-7K.png") },
            }),
            _c("div", { staticClass: "hover-text" }, [
              _c("div", [_vm._v(" 电动汽车交流充电桩 ")]),
              _vm._v(" QYCC-7K "),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "QYCC-ACZ-7K",
            staticStyle: {
              width: "275px",
              height: "500px",
              "margin-left": "10px",
            },
          },
          [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("@/assets/page/QYCC-ACZ-7K.png") },
            }),
            _c("div", { staticClass: "QYCC-ACZ-7K-hover-text" }, [
              _c("div", [_vm._v(" 电动汽车交流充电桩 ")]),
              _vm._v(" QYCC-ACZ-7K "),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "420px",
              height: "500px",
              "margin-left": "10px",
            },
          },
          [
            _c("div", { staticClass: "QYCC-02" }, [
              _c("img", {
                staticClass: "img",
                staticStyle: { height: "240px" },
                attrs: { src: require("@/assets/page/QYCC-02.png") },
              }),
              _c("div", { staticClass: "QYCC-02-hover-text" }, [
                _c("div", [_vm._v(" 电瓶车集中式充电桩 ")]),
                _vm._v(" QYCC-02 "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "QYCC-12", staticStyle: { "margin-top": "10px" } },
              [
                _c("img", {
                  staticClass: "img",
                  staticStyle: { height: "240px" },
                  attrs: { src: require("@/assets/page/QYCC-12.png") },
                }),
                _c("div", { staticClass: "QYCC-12-hover-text" }, [
                  _c("div", [_vm._v(" 电瓶车分布式充电桩 ")]),
                  _vm._v(" QYCC-12 "),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "hzgy" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 合作共赢 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "hzsj" }, [
        _c("div", { staticClass: "left1 left" }, [
          _c("div", { staticClass: "left1" }, [
            _c("div", { staticClass: "left2" }, [
              _c("div", { staticClass: "left3" }, [
                _c("div", { staticClass: "left4" }, [
                  _c("div", { staticClass: "left5" }, [
                    _c("div", { staticClass: "left6" }, [
                      _c("div", { staticClass: "left7" }, [
                        _c("div", { staticClass: "left8" }, [
                          _c("div", { staticClass: "left9" }, [
                            _c("div", { staticClass: "left10" }, [
                              _c("div", { staticClass: "left11" }, [
                                _c("div", { staticClass: "left12" }, [
                                  _c("div", { staticClass: "left13" }, [
                                    _c("div", { staticClass: "left14" }, [
                                      _c("div", { staticClass: "left15" }, [
                                        _c("div", { staticClass: "left16" }, [
                                          _c("div", { staticClass: "bgy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/bgy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "ccwy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/ccwy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "zxwy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/zxwy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "wkwy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/wkwy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "blwy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/blwy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "xbcj" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/xbcj.png"),
                                              },
                                            }),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "center" }, [
          _c("div", [
            _c("img", {
              staticStyle: { "margin-top": "62px" },
              attrs: { src: require("@/assets/page/wx.png") },
            }),
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(" 这是莫比乌斯环"),
            _c("br"),
            _vm._v(" 代表无限"),
            _c("br"),
            _vm._v(" 期待您的加入"),
            _c("br"),
            _vm._v(" 带来无限的可能 "),
          ]),
        ]),
        _c("div", { staticClass: "left1 right" }, [
          _c("div", { staticClass: "left1" }, [
            _c("div", { staticClass: "left2" }, [
              _c("div", { staticClass: "left3" }, [
                _c("div", { staticClass: "left4" }, [
                  _c("div", { staticClass: "left5" }, [
                    _c("div", { staticClass: "left6" }, [
                      _c("div", { staticClass: "left7" }, [
                        _c("div", { staticClass: "left8" }, [
                          _c("div", { staticClass: "left9" }, [
                            _c("div", { staticClass: "left10" }, [
                              _c("div", { staticClass: "left11" }, [
                                _c("div", { staticClass: "left12" }, [
                                  _c("div", { staticClass: "left13" }, [
                                    _c("div", { staticClass: "left14" }, [
                                      _c("div", { staticClass: "left15" }, [
                                        _c("div", { staticClass: "left16" }, [
                                          _c("div", { staticClass: "fr" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/fr.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "csh" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/csh.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "zhwy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/zhwy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "fxwy" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/fxwy.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "xl" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/xl.png"),
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "ldkg" }, [
                                            _c("img", {
                                              staticClass: "img",
                                              attrs: {
                                                src: require("@/assets/page/ldkg.png"),
                                              },
                                            }),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }