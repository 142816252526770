// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/page/news-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Variables */\n[data-v-764a0da0]:export {\n  menuBg: white;\n  menuText: rgba(0, 0, 0, 0.65);\n  menuActiveText: #005BB5;\n}\n\n/* Mixins */\n.body[data-v-764a0da0] {\n  width: 100%;\n  height: 100%;\n  min-width: 1400px;\n  background: #FFFFFF;\n}\n.body .title-bg[data-v-764a0da0] {\n  width: 100%;\n  height: 240px;\n  position: relative;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 240px;\n  text-align: center;\n}\n.body .news[data-v-764a0da0] {\n  padding: 100px 270px;\n}\n.body .news .qy[data-v-764a0da0] {\n  width: 100%;\n  padding: 50px;\n  background: #36C7B5;\n}\n.body .news .qy .title[data-v-764a0da0] {\n  font-size: 50px;\n  font-weight: bold;\n  line-height: 66px;\n  color: #FFFFFF;\n}\n.body .news .qy .time[data-v-764a0da0] {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 26px;\n  margin: 20px 0;\n  color: #FFFFFF;\n}\n.body .news .qy .description[data-v-764a0da0] {\n  font-size: 20px;\n  font-weight: 400;\n  color: #FFFFFF;\n}\n.body .news-list[data-v-764a0da0] {\n  width: 434px;\n}\n.body .news-list .news-example[data-v-764a0da0] {\n  margin-bottom: 100px;\n}\n.body .news-list .news-example .title[data-v-764a0da0] {\n  line-height: 26px;\n  font-size: 20px;\n  font-weight: bold;\n  padding: 20px 20px 10px;\n  color: #333333;\n}\n.body .news-list .news-example .time[data-v-764a0da0] {\n  line-height: 26px;\n  font-size: 20px;\n  padding: 0 20px 20px;\n  font-weight: 400;\n}\n.body .news-list .news-example[data-v-764a0da0]:hover {\n  -webkit-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);\n          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);\n  cursor: pointer;\n}\n.body .news-list + .news-list[data-v-764a0da0] {\n  margin-right: 30px;\n}", ""]);
// Exports
module.exports = exports;
