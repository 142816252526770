var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.cooperationActiveName === "normal",
            expression: "cooperationActiveName === 'normal'",
          },
        ],
        staticClass: "body",
      },
      [
        _vm._m(0),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _c("div", { staticClass: "hzfs" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 合作方式 ")]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "cw-dls" }, [
            _c("div", { staticClass: "dls" }, [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "title" }, [_vm._v(" 成为代理商 ")]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 方案支持 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 培训支持 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 售后支持 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 运营服务 / "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.goForm("dls")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "line-height": "44px",
                          color: "#FFFFFF",
                          "font-size": "20px",
                        },
                      },
                      [_vm._v("立即申请")]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "cw-hhr" }, [
            _c("div", { staticClass: "hhr" }, [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "title" }, [_vm._v(" 成为合伙人 ")]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 场站选址 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 方案集成 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 设备安装 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 技术对接 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 运营服务 / "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.goForm("hhr")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "line-height": "44px",
                          color: "#FFFFFF",
                          "font-size": "20px",
                        },
                      },
                      [_vm._v("立即申请")]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "jrqy" }, [
            _c("div", { staticClass: "qy" }, [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "title" }, [_vm._v(" 加入旗鱼 ")]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 人才发展 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 环境优越 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 文化多元 / "),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(" / 福利丰富 / "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.goForm("jrqy")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "line-height": "44px",
                          color: "#FFFFFF",
                          "font-size": "20px",
                        },
                      },
                      [_vm._v("立即加入")]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm.cooperationActiveName === "dls"
      ? _c("div", { staticClass: "dls-forms" }, [
          _c("div", { staticClass: "dls-form" }, [
            _c("img", {
              staticStyle: {
                width: "495px",
                height: "66px",
                "margin-top": "100px",
              },
              attrs: { src: require("@/assets/page/dls-title.png") },
            }),
            _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _vm._v(" 您的姓名 "),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入您的姓名" },
                      model: {
                        value: _vm.dlsForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.dlsForm, "name", $$v)
                        },
                        expression: "dlsForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _vm._v(" 联系方式 "),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入您的联系方式" },
                      model: {
                        value: _vm.dlsForm.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.dlsForm, "phone", $$v)
                        },
                        expression: "dlsForm.phone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "margin-top": "50px" } },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("div", { staticClass: "form-label" }, [
                        _vm._v(" 所在城市 "),
                      ]),
                      _c("el-input", {
                        attrs: { placeholder: "请输入您的所在城市" },
                        model: {
                          value: _vm.dlsForm.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.dlsForm, "city", $$v)
                          },
                          expression: "dlsForm.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("div", { staticClass: "form-label" }, [
                        _vm._v(" 职业单位 "),
                      ]),
                      _c("el-input", {
                        attrs: { placeholder: "请输入您的职业单位" },
                        model: {
                          value: _vm.dlsForm.work,
                          callback: function ($$v) {
                            _vm.$set(_vm.dlsForm, "work", $$v)
                          },
                          expression: "dlsForm.work",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "50px" } },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _vm._v(" 合作意向及需求 "),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 8,
                      placeholder: "请输入您的合作意向及需求",
                    },
                    model: {
                      value: _vm.dlsForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.dlsForm, "content", $$v)
                      },
                      expression: "dlsForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    "margin-top": "100px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        padding: "17px 100px",
                        "border-radius": "30px",
                      },
                      attrs: { type: "primary" },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm.cooperationActiveName === "hhr"
      ? _c("div", { staticClass: "hhr-forms" }, [
          _c("div", { staticClass: "hhr-form" }, [
            _c("img", {
              staticStyle: {
                width: "495px",
                height: "66px",
                "margin-top": "100px",
              },
              attrs: { src: require("@/assets/page/hhr-title.png") },
            }),
            _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _vm._v(" 您的姓名 "),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入您的姓名" },
                      model: {
                        value: _vm.hhrForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.hhrForm, "name", $$v)
                        },
                        expression: "hhrForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _vm._v(" 联系方式 "),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入您的联系方式" },
                      model: {
                        value: _vm.hhrForm.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.hhrForm, "phone", $$v)
                        },
                        expression: "hhrForm.phone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "margin-top": "50px" } },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("div", { staticClass: "form-label" }, [
                        _vm._v(" 所在城市 "),
                      ]),
                      _c("el-input", {
                        attrs: { placeholder: "请输入您的所在城市" },
                        model: {
                          value: _vm.hhrForm.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.hhrForm, "city", $$v)
                          },
                          expression: "hhrForm.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("div", { staticClass: "form-label" }, [
                        _vm._v(" 职业单位 "),
                      ]),
                      _c("el-input", {
                        attrs: { placeholder: "请输入您的职业单位" },
                        model: {
                          value: _vm.hhrForm.work,
                          callback: function ($$v) {
                            _vm.$set(_vm.hhrForm, "work", $$v)
                          },
                          expression: "hhrForm.work",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "50px" } },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _vm._v(" 合作意向及需求 "),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 8,
                      placeholder: "请输入您的合作意向及需求",
                    },
                    model: {
                      value: _vm.hhrForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.hhrForm, "content", $$v)
                      },
                      expression: "hhrForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    "margin-top": "100px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        padding: "17px 100px",
                        "border-radius": "30px",
                      },
                      attrs: { type: "primary" },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm.cooperationActiveName === "jrqy"
      ? _c("div", { staticClass: "jrqy-forms" }, [
          _c("div", { staticClass: "jrqy-form" }, [
            _c("img", {
              staticStyle: {
                width: "495px",
                height: "66px",
                "margin-top": "100px",
              },
              attrs: { src: require("@/assets/page/jrqy-title.png") },
            }),
            _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "zsjl" }, [
                _vm._m(5),
                _vm._m(6),
                !_vm.zsjlInfo
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.zsjlInfo = !_vm.zsjlInfo
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#666666",
                              "font-size": "20px",
                            },
                          },
                          [_vm._v(" 查看详情 ")]
                        ),
                        _c("svg-icon", {
                          staticStyle: {
                            "margin-left": "4px",
                            width: "26px",
                            height: "26px",
                            "vertical-align": "-6px",
                          },
                          attrs: { name: "select-down" },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.zsjlInfo = !_vm.zsjlInfo
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#666666",
                              "font-size": "20px",
                            },
                          },
                          [_vm._v(" 收起详情 ")]
                        ),
                        _c("svg-icon", {
                          staticStyle: {
                            "margin-left": "10px",
                            width: "26px",
                            height: "26px",
                            "vertical-align": "-12px",
                          },
                          attrs: { name: "arrow-up" },
                        }),
                        _vm._m(7),
                      ],
                      1
                    ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "92px",
                      right: "50px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          padding: "17px 80px",
                          "border-radius": "30px",
                        },
                        attrs: { type: "primary" },
                      },
                      [_vm._v(" 申请岗位 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "zsjl", staticStyle: { "margin-top": "50px" } },
                [
                  _vm._m(8),
                  _vm._m(9),
                  !_vm.qdkfInfo
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.qdkfInfo = !_vm.qdkfInfo
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "20px",
                              },
                            },
                            [_vm._v(" 查看详情 ")]
                          ),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "4px",
                              width: "26px",
                              height: "26px",
                              "vertical-align": "-6px",
                            },
                            attrs: { name: "select-down" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.qdkfInfo = !_vm.qdkfInfo
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "20px",
                              },
                            },
                            [_vm._v(" 收起详情 ")]
                          ),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "26px",
                              height: "26px",
                              "vertical-align": "-12px",
                            },
                            attrs: { name: "arrow-up" },
                          }),
                          _vm._m(10),
                          _vm._m(11),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "92px",
                        right: "50px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            padding: "17px 80px",
                            "border-radius": "30px",
                          },
                          attrs: { type: "primary" },
                        },
                        [_vm._v(" 申请岗位 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "zsjl", staticStyle: { "margin-top": "50px" } },
                [
                  _vm._m(12),
                  _vm._m(13),
                  !_vm.gjhdInfo
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.gjhdInfo = !_vm.gjhdInfo
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "20px",
                              },
                            },
                            [_vm._v(" 查看详情 ")]
                          ),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "4px",
                              width: "26px",
                              height: "26px",
                              "vertical-align": "-6px",
                            },
                            attrs: { name: "select-down" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.gjhdInfo = !_vm.gjhdInfo
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "20px",
                              },
                            },
                            [_vm._v(" 收起详情 ")]
                          ),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "26px",
                              height: "26px",
                              "vertical-align": "-12px",
                            },
                            attrs: { name: "arrow-up" },
                          }),
                          _vm._m(14),
                          _vm._m(15),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "92px",
                        right: "50px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            padding: "17px 80px",
                            "border-radius": "30px",
                          },
                          attrs: { type: "primary" },
                        },
                        [_vm._v(" 申请岗位 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "zsjl", staticStyle: { "margin-top": "50px" } },
                [
                  _vm._m(16),
                  _vm._m(17),
                  !_vm.dhxsInfo
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dhxsInfo = !_vm.dhxsInfo
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "20px",
                              },
                            },
                            [_vm._v(" 查看详情 ")]
                          ),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "4px",
                              width: "26px",
                              height: "26px",
                              "vertical-align": "-6px",
                            },
                            attrs: { name: "select-down" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dhxsInfo = !_vm.dhxsInfo
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#666666",
                                "font-size": "20px",
                              },
                            },
                            [_vm._v(" 收起详情 ")]
                          ),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "26px",
                              height: "26px",
                              "vertical-align": "-12px",
                            },
                            attrs: { name: "arrow-up" },
                          }),
                          _vm._m(18),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "92px",
                        right: "50px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            padding: "17px 80px",
                            "border-radius": "30px",
                          },
                          attrs: { type: "primary" },
                        },
                        [_vm._v(" 申请岗位 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title-bg" }, [
      _c("img", {
        staticStyle: {
          width: "428px",
          height: "66px",
          display: "inline-block",
          "margin-top": "87px",
        },
        attrs: { src: require("@/assets/page/cooperation-win-title.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "requirement-analysis", attrs: { id: "scqj" } },
      [
        _vm._v(
          "        近年来，我国电动自行车年销量超过3000万辆，社会保有量接近3亿辆。庞大的电动自行车数量也带来了安"
        ),
        _c("br"),
        _vm._v(
          " 全隐患，许多居民将电动自行车放至楼道、家中等住宅内充电，而过度充电、线路老化以及充电器使用不当导致"
        ),
        _c("br"),
        _vm._v(
          " 的电动自行车自燃、爆炸事件时有发生。2018年以来，全国各地政府先后出台针对电动车充电的治理通知，明令"
        ),
        _c("br"),
        _vm._v(" 禁止电动自行车上楼充电，鼓励推广充电和消防设施建设，"),
        _c("span", { staticStyle: { color: "#36C7B5" } }, [
          _vm._v("市场需求持续扩大，智能充电站紧缺。"),
        ]),
        _vm._v("                         "),
        _c("div", { staticClass: "imgs" }, [
          _c("img", {
            staticStyle: {
              width: "420px",
              height: "320px",
              display: "inline-block",
              "margin-top": "100px",
            },
            attrs: { src: require("@/assets/page/cooperation-img1.png") },
          }),
          _c("img", {
            staticStyle: {
              width: "420px",
              height: "320px",
              display: "inline-block",
              "margin-top": "100px",
              "margin-left": "60px",
            },
            attrs: { src: require("@/assets/page/cooperation-img2.png") },
          }),
          _c("img", {
            staticStyle: {
              width: "420px",
              height: "320px",
              display: "inline-block",
              "margin-top": "100px",
              "margin-left": "60px",
            },
            attrs: { src: require("@/assets/page/cooperation-img3.png") },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "qy-ys", attrs: { id: "qyys" } }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 旗鱼优势 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "ys" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", [
            _c("div", { staticClass: "number" }, [_vm._v(" 30多项 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 拥 有 专 利 证 书 ")]),
          ]),
          _c("div", [
            _c("img", {
              staticStyle: {
                width: "56px",
                height: "70px",
                display: "inline-block",
                "margin-top": "16px",
              },
              attrs: { src: require("@/assets/qyys/zlzs.png") },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "item", staticStyle: { "margin-left": "277px" } },
          [
            _c("div", [
              _c("div", { staticClass: "number" }, [_vm._v(" 200余座 ")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" 业 务 覆 盖 城 市 "),
              ]),
            ]),
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "56px",
                  height: "70px",
                  display: "inline-block",
                  "margin-top": "16px",
                },
                attrs: { src: require("@/assets/qyys/fgcs.png") },
              }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "ys" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", [
            _c("div", { staticClass: "number" }, [_vm._v(" 10多项 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 获 得 成 就 荣 誉 ")]),
          ]),
          _c("div", [
            _c("img", {
              staticStyle: {
                width: "56px",
                height: "70px",
                display: "inline-block",
                "margin-top": "16px",
              },
              attrs: { src: require("@/assets/qyys/cjry.png") },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "item", staticStyle: { "margin-left": "277px" } },
          [
            _c("div", [
              _c("div", { staticClass: "number" }, [_vm._v(" 2500余家 ")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" 设 有 运 营 网 点 "),
              ]),
            ]),
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "56px",
                  height: "70px",
                  display: "inline-block",
                  "margin-top": "16px",
                },
                attrs: { src: require("@/assets/qyys/yywd.png") },
              }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "ys" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", [
            _c("div", { staticClass: "number" }, [_vm._v(" 20多家 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 战 略 合 作 伙 伴 ")]),
          ]),
          _c("div", [
            _c("img", {
              staticStyle: {
                width: "56px",
                height: "70px",
                display: "inline-block",
                "margin-top": "16px",
              },
              attrs: { src: require("@/assets/qyys/hzhb.png") },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "item", staticStyle: { "margin-left": "277px" } },
          [
            _c("div", [
              _c("div", { staticClass: "number" }, [_vm._v(" 500000余次 ")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" 电 桩 充 电 次 数 "),
              ]),
            ]),
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "56px",
                  height: "70px",
                  display: "inline-block",
                  "margin-top": "16px",
                },
                attrs: { src: require("@/assets/qyys/cdcs.png") },
              }),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "hzbz", attrs: { id: "hzbz" } }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 合作保障 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "hzbz-imgs" }, [
        _c("div", { staticClass: "hzbz-example" }, [
          _c("img", {
            staticStyle: {
              width: "100%",
              height: "260px",
              display: "inline-block",
              "vertical-align": "bottom",
            },
            attrs: { src: require("@/assets/cooperation/bzj.png") },
          }),
          _c("div", { staticClass: "name" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 100%返还保障金 ")]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "hzbz-example",
            staticStyle: { "margin-left": "60px" },
          },
          [
            _c("img", {
              staticStyle: {
                width: "100%",
                height: "260px",
                display: "inline-block",
                "vertical-align": "bottom",
              },
              attrs: { src: require("@/assets/cooperation/lsfc.png") },
            }),
            _c("div", { staticClass: "name" }, [
              _c("div", { staticClass: "title" }, [_vm._v(" 90%流水分成 ")]),
            ]),
          ]
        ),
        _c("div", { staticClass: "hzbz-example" }, [
          _c("img", {
            staticStyle: {
              width: "100%",
              height: "260px",
              display: "inline-block",
              "vertical-align": "bottom",
            },
            attrs: { src: require("@/assets/cooperation/zzssdz.png") },
          }),
          _c("div", { staticClass: "name" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 资金实时到账 ")]),
          ]),
        ]),
        _c("div", { staticClass: "hzbz-example" }, [
          _c("img", {
            staticStyle: {
              width: "100%",
              height: "260px",
              display: "inline-block",
              "vertical-align": "bottom",
            },
            attrs: { src: require("@/assets/cooperation/sckt.png") },
          }),
          _c("div", { staticClass: "name" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 免费指导市场开拓 ")]),
          ]),
        ]),
        _c("div", { staticClass: "hzbz-example" }, [
          _c("img", {
            staticStyle: {
              width: "100%",
              height: "260px",
              display: "inline-block",
              "vertical-align": "bottom",
            },
            attrs: { src: require("@/assets/cooperation/zbxz.png") },
          }),
          _c("div", { staticClass: "name" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 项目签约总部协助 ")]),
          ]),
        ]),
        _c("div", { staticClass: "hzbz-example" }, [
          _c("img", {
            staticStyle: {
              width: "100%",
              height: "260px",
              display: "inline-block",
              "vertical-align": "bottom",
            },
            attrs: { src: require("@/assets/cooperation/zsbx.png") },
          }),
          _c("div", { staticClass: "name" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 设备终生保修 ")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "hzlc", attrs: { id: "hzlc" } }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 合作流程 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "lc" }, [
        _c("div", { staticClass: "imgs" }, [
          _c("img", {
            staticStyle: { width: "276px", height: "140px" },
            attrs: { src: require("@/assets/cooperation/tjsq.png") },
          }),
          _c("div", { staticClass: "text" }, [_vm._v(" 提交申请 ")]),
        ]),
        _c("div", { staticClass: "imgs" }, [
          _c("img", {
            staticStyle: { width: "276px", height: "140px" },
            attrs: { src: require("@/assets/cooperation/scgt.png") },
          }),
          _c("div", { staticClass: "text" }, [_vm._v(" 市场沟通 ")]),
        ]),
        _c("div", { staticClass: "imgs" }, [
          _c("img", {
            staticStyle: { width: "276px", height: "140px" },
            attrs: { src: require("@/assets/cooperation/qsht.png") },
          }),
          _c("div", { staticClass: "text" }, [_vm._v(" 签署合同 ")]),
        ]),
        _c("div", { staticClass: "imgs" }, [
          _c("img", {
            staticStyle: { width: "276px", height: "140px" },
            attrs: { src: require("@/assets/cooperation/zbfh.png") },
          }),
          _c("div", { staticClass: "text" }, [_vm._v(" 总部发货 ")]),
        ]),
        _c("div", { staticClass: "imgs" }, [
          _c("img", {
            staticStyle: { width: "276px", height: "140px" },
            attrs: { src: require("@/assets/cooperation/azsx.png") },
          }),
          _c("div", { staticClass: "text" }, [_vm._v(" 安装上线 ")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex justify-content-between" }, [
      _c("div", { staticClass: "jrqy-title" }, [_vm._v(" 招商经理 ")]),
      _c("div", { staticClass: "jrqy-money" }, [_vm._v(" 6K-12K/月 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-type" }, [
      _c("div", [
        _vm._v(" 销售类     |     南昌     |      3-5年     |     大专 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-content" }, [
      _vm._v(" 职位描述："),
      _c("br"),
      _vm._v(" 1、性别；不限，要求形象好。"),
      _c("br"),
      _vm._v(" 2、年龄要求；22-35周岁。"),
      _c("br"),
      _vm._v(
        " 3、学历要求；大专以上学历，市场营销专业、工商企业管理专业者优先。"
      ),
      _c("br"),
      _vm._v(
        " 4、工作经验；两年以上销售经验或市场招商经验，从事过金融招商、互联网招商、企业管理培训者、充电桩招商加盟经验优先。"
      ),
      _c("br"),
      _vm._v(" 5、具有敏锐的市场洞察力、应变能力、和创新思维能力。"),
      _c("br"),
      _vm._v(" 6、能适应长期加班，执行力强、抗高压。 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex justify-content-between" }, [
      _c("div", { staticClass: "jrqy-title" }, [
        _vm._v(" 中级前端开发工程师 "),
      ]),
      _c("div", { staticClass: "jrqy-money" }, [_vm._v(" 8K-14K/月 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-type" }, [
      _c("div", [
        _vm._v(" 技术类     |     南昌     |      3-5年     |     大专 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-content" }, [
      _vm._v(" 职位描述："),
      _c("br"),
      _vm._v(" 1、分析业务，编写需求规格说明书"),
      _c("br"),
      _vm._v(" 2、根据需求，编写前端应用设计文档"),
      _c("br"),
      _vm._v(" 3、根据设计，编写代码实现"),
      _c("br"),
      _vm._v(" 4、持续地交付和维护前端软件产品"),
      _c("br"),
      _vm._v(" 5、研究新技术，提升团队开发的质量和效率"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "jrqy-content", staticStyle: { "margin-top": "20px" } },
      [
        _vm._v(" 任职条件："),
        _c("br"),
        _vm._v(" 1、能够熟练使用html，css和js语言编写Web页面"),
        _c("br"),
        _vm._v(" 2、能够熟练使用vue框架编写单页应用"),
        _c("br"),
        _vm._v(" 3、熟悉element-ui，axios和async-validator等工具库"),
        _c("br"),
        _vm._v(" 4、熟悉vue-cli，npm和webpack等前端自动化工具"),
        _c("br"),
        _vm._v(" 5、能够熟练使用git管理代码"),
        _c("br"),
        _vm._v(" 6、能够熟练使用chrome对前端应用进行测试和调试"),
        _c("br"),
        _vm._v(" 7、能够熟练使用nginx部署前端应用"),
        _c("br"),
        _vm._v(" 8、具有较强的逻辑思维能力，能够分析和实现复杂的业务需求"),
        _c("br"),
        _vm._v(
          " 9、具有较强的学习能力和学习积极性，能够举一反三 ，会主动地去学习新知识，掌握新技术"
        ),
        _c("br"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex justify-content-between" }, [
      _c("div", { staticClass: "jrqy-title" }, [
        _vm._v(" 高级Java开发工程师 "),
      ]),
      _c("div", { staticClass: "jrqy-money" }, [_vm._v(" 10K-15K/月 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-type" }, [
      _c("div", [
        _vm._v(" 技术类     |     南昌     |      1-3年     |     大专 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-content" }, [
      _vm._v(" 职位描述："),
      _c("br"),
      _vm._v(
        " 1、负责公司开发架构的模块设计，编码测试以及文档编写等工作； 2、负责系统性能优化，架构优化，保证服务性能和稳定性； 3、参与前沿技术选型，新兴技术研究，满足产品未来规划； "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "jrqy-content", staticStyle: { "margin-top": "20px" } },
      [
        _vm._v(" 任职条件："),
        _c("br"),
        _vm._v(" 1、本科及以上学历，计算机、软件工程或相关专业毕业；"),
        _c("br"),
        _vm._v(
          " 2、2年及以上的Java开发经验，扎实的编程功底，熟悉常用的数据结构和算法；"
        ),
        _c("br"),
        _vm._v(
          " 3、深入了解Spring/Spring cloud/Springbot等Java主流框架至少一种（框架提供的特性及其实现原理）；"
        ),
        _c("br"),
        _vm._v(" 4、熟悉SQL及NOSQL，了解MySQL/Redis/MangoDB等至少一种数据库；"),
        _c("br"),
        _vm._v(
          " 5、熟悉常用设计模式，有大型分布式、集群、高并发、高负载、高可用系统设计经验；；"
        ),
        _c("br"),
        _vm._v(" 6、善于技术方案的策划和撰写，具有良好的文字和语言表达能力；"),
        _c("br"),
        _vm._v(
          " 7、对程序开发有浓厚的兴趣，喜欢钻研技术，善于思考，有强烈的责任心；"
        ),
        _c("br"),
        _vm._v(" 8、较强的领悟能力和沟通能力，有很好的团队合作精神； "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex justify-content-between" }, [
      _c("div", { staticClass: "jrqy-title" }, [_vm._v(" 电话销售 ")]),
      _c("div", { staticClass: "jrqy-money" }, [_vm._v(" 5K-10K/月 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-type" }, [
      _c("div", [
        _vm._v(" 销售类     |     南昌     |      1-3年     |     大专 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "jrqy-content" }, [
      _vm._v(" 职位描述："),
      _c("br"),
      _vm._v(
        " 1、公司提供客户资源，使用电话、微信等网络工具联系客户，邀约客户到公司; 2、了解客户的具体需求，向客户介绍产品，服务周到尽力促成成交; 3、接听客户来电，帮助客户了解产品信息，针对客户的需求介绍相关的产品; 4、记录销售过程，跟踪并确保及时收回销售款项; 5、对客户进行回访，保持良好的客户关系; 6、电话销售数据统计分析; 7、完成领导交给的其他任务。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }