
export class DlsForm {
    name: string
    phone: string
    city: string
    work: string
    content: string

    constructor(name: string, phone: string, city: string, work: string, content: string) {
        this.name = name;
        this.phone = phone;
        this.city = city;
        this.work = work;
        this.content = content;
    }
}

export class HhrForm {
    name: string
    phone: string
    city: string
    work: string
    content: string

    constructor(name: string, phone: string, city: string, work: string, content: string) {
        this.name = name;
        this.phone = phone;
        this.city = city;
        this.work = work;
        this.content = content;
    }
}
