import { Component, Vue, Watch } from 'vue-property-decorator';

import { loading } from '@/commons/decorators/decorator';
// 页面
@Component({ name: 'homePage' })
export default class extends Vue {
    goToAboutQy() {
        this.$emit('aboutQy', 'top')
    }
    created() {
    }
}
