import { Component, Vue, Watch } from 'vue-property-decorator';

// 新闻资讯页面
@Component({ name: 'News' })
export default class extends Vue {
    public activeName = 'all' || 'dpccdz' || 'ddqccdz' || 'chdg' || 'hwyddy'
    public changeActiveName(name: string) {
        this.activeName = name;
        document.querySelector(`#${name}`).scrollIntoView(true)
        // document.querySelector(`#top`).scrollIntoView(true)
    }
    created() {
        this.activeName = 'all'
    }
}
