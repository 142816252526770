import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserModule } from "@/store/modules/user";
import { DlsForm, HhrForm } from "@/entities/dl";

// 合作共赢页面
@Component({ name: 'News' })
export default class extends Vue {
    // @ts-ignore
    public dlsForm = new DlsForm()
    // @ts-ignore
    public hhrForm = new HhrForm()
    // 职位详情是否展示
    public zsjlInfo: boolean = false
    public qdkfInfo: boolean = false
    public gjhdInfo: boolean = false
    public dhxsInfo: boolean = false
    public cooperationActiveName = 'normal' || 'dls' || 'hhr' || 'jrqy'
    @Watch('$store.state.user.cooperationName', {deep: true, immediate: true})
    public changeName() {
        this.cooperationActiveName = UserModule.cooperationName || 'normal'
    }
    // 合作共赢中表單页面
    public goForm(name: string) {
        this.cooperationActiveName = name
        this.$nextTick(() => {
            document.querySelector(`#top`).scrollIntoView(true)
        })
    }
    created() {
        this.cooperationActiveName = UserModule.cooperationName || 'normal'
    }
}
