var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "title-bg" }, [
        _c("img", {
          staticStyle: {
            width: "428px",
            height: "66px",
            display: "inline-block",
            "margin-top": "87px",
          },
          attrs: { src: require("@/assets/page/news-title-bg.png") },
        }),
      ]),
      _c("div", { staticClass: "news" }, [
        _c("img", {
          staticStyle: {
            width: "100%",
            height: "600px",
            display: "inline-block",
            "vertical-align": "bottom",
          },
          attrs: { src: require("@/assets/page/building.png") },
        }),
        _c("div", { staticClass: "qy" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" 旗鱼充充荣获2021年度最具创新力品牌 "),
          ]),
          _c("div", { staticClass: "time" }, [_vm._v(" 2022-01-28 ")]),
          _c("div", { staticClass: "description" }, [
            _vm._v(" 旗鱼充充荣获2021年度最具创新力品牌 "),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "flex-wrap": "wrap",
            display: "flex",
            "padding-left": "270px",
          },
        },
        [
          _c("div", { staticClass: "news-list" }, [
            _c("div", { staticClass: "news-example" }, [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "260px",
                  display: "inline-block",
                  "vertical-align": "bottom",
                },
                attrs: { src: require("@/assets/page/xcylxfh.png") },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #EEEEEE",
                    "border-top": "none",
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 中国创新创业领袖峰会 "),
                  ]),
                  _c("div", { staticClass: "time" }, [_vm._v(" 2022-08-02 ")]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "news-list",
              staticStyle: { "margin-left": "30px" },
            },
            [
              _c("div", { staticClass: "news-example" }, [
                _c("img", {
                  staticStyle: {
                    width: "100%",
                    height: "260px",
                    display: "inline-block",
                    "vertical-align": "bottom",
                  },
                  attrs: { src: require("@/assets/page/glsfshz.png") },
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      border: "1px solid #EEEEEE",
                      "border-top": "none",
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 桂林市居民自建房发生火灾 "),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(" 2022-07-31 "),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "news-list" }, [
            _c("div", { staticClass: "news-example" }, [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "260px",
                  display: "inline-block",
                  "vertical-align": "bottom",
                },
                attrs: { src: require("@/assets/page/gzcdss.png") },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #EEEEEE",
                    "border-top": "none",
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 广州住宅建筑100%建设集中充电设施 "),
                  ]),
                  _c("div", { staticClass: "time" }, [_vm._v(" 2022-07-26 ")]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "news-list" }, [
            _c("div", { staticClass: "news-example" }, [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "260px",
                  display: "inline-block",
                  "vertical-align": "bottom",
                },
                attrs: { src: require("@/assets/page/bjsha.png") },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #EEEEEE",
                    "border-top": "none",
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 北京通州电动自行车失火案一审宣判 "),
                  ]),
                  _c("div", { staticClass: "time" }, [_vm._v(" 2022-06-15 ")]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "news-list" }, [
            _c("div", { staticClass: "news-example" }, [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "260px",
                  display: "inline-block",
                  "vertical-align": "bottom",
                },
                attrs: { src: require("@/assets/page/gdjzddcrh.png") },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #EEEEEE",
                    "border-top": "none",
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 广东立法禁止电动自行车及电池入户 "),
                  ]),
                  _c("div", { staticClass: "time" }, [_vm._v(" 2022-04-02 ")]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "news-list" }, [
            _c("div", { staticClass: "news-example" }, [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "260px",
                  display: "inline-block",
                  "vertical-align": "bottom",
                },
                attrs: { src: require("@/assets/page/ddclhgzzd.png") },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #EEEEEE",
                    "border-top": "none",
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 电动自行车成全国两会关注重点 "),
                  ]),
                  _c("div", { staticClass: "time" }, [_vm._v(" 2022-03-04 ")]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }