var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "body" }, [
    _vm._m(0),
    _c("div", { staticClass: "product-tab", attrs: { id: "all" } }, [
      _c(
        "div",
        {
          staticClass: "name-title",
          class: [{ active: _vm.activeName === "all" }],
          on: {
            click: function ($event) {
              return _vm.changeActiveName("all")
            },
          },
        },
        [_vm._v(" 全部 ")]
      ),
      _c(
        "div",
        {
          staticClass: "name-title",
          class: [{ active: _vm.activeName === "dpccdz" }],
          on: {
            click: function ($event) {
              return _vm.changeActiveName("dpccdz")
            },
          },
        },
        [_vm._v(" 电瓶车充电桩 ")]
      ),
      _c(
        "div",
        {
          staticClass: "name-title",
          class: [{ active: _vm.activeName === "ddqccdz" }],
          on: {
            click: function ($event) {
              return _vm.changeActiveName("ddqccdz")
            },
          },
        },
        [_vm._v(" 电动汽车充电桩 ")]
      ),
      _c(
        "div",
        {
          staticClass: "name-title",
          class: [{ active: _vm.activeName === "chdg" }],
          on: {
            click: function ($event) {
              return _vm.changeActiveName("chdg")
            },
          },
        },
        [_vm._v(" 充/换电柜 ")]
      ),
      _c(
        "div",
        {
          staticClass: "name-title",
          class: [{ active: _vm.activeName === "hwyddy" }],
          on: {
            click: function ($event) {
              return _vm.changeActiveName("hwyddy")
            },
          },
        },
        [_vm._v(" 户外移动电源 ")]
      ),
    ]),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title-bg" }, [
      _c("img", {
        staticStyle: {
          width: "428px",
          height: "66px",
          display: "inline-block",
          "margin-top": "87px",
        },
        attrs: { src: require("@/assets/page/product-center-title.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "body-content", attrs: { id: "dpccdz" } }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 电瓶车集中式充电桩 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "flex justify-content-between" }, [
        _c("div", { staticClass: "picture" }, [
          _c("img", {
            staticStyle: {
              width: "400px",
              height: "300px",
              display: "inline-block",
              "margin-top": "100px",
              "margin-left": "50px",
            },
            attrs: { src: require("@/assets/page/QYCC-12.png") },
          }),
        ]),
        _c("div", { staticClass: "description" }, [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
            [
              _c(
                "div",
                {
                  staticClass: "description-text",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 产品型号 ")]),
                  _c("div", { staticClass: "value" }, [_vm._v(" QYCC-12 ")]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "description-text",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 防护等级 ")]),
                  _c("div", { staticClass: "value" }, [_vm._v(" IP54 ")]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 额定电压 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" AC220V / 50Hz ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 信号网络 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 4G ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 待机功率 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 3W ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 刷卡 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 支持 ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 满载最大电流 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 27A ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 环境温度 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" -25~80℃ ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 满载最大功率 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 6000W ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 执行标准 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" QYCC001-2021 ")]),
            ]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 单口最大功率 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 500W ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 充电接口 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 12口 ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 外壳材质 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 阻燃ABS ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 主机尺寸 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 330*230*60mm ")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "body-content", attrs: { id: "ddqccdz" } },
      [
        _c("div", { staticClass: "title" }, [_vm._v(" 电动汽车交流充电桩 ")]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "flex justify-content-between" }, [
          _c("div", { staticClass: "picture" }, [
            _c("img", {
              staticStyle: {
                width: "280px",
                height: "500px",
                display: "inline-block",
                "margin-left": "110px",
              },
              attrs: { src: require("@/assets/page/QYCC-ACZ-7K.png") },
            }),
          ]),
          _c("div", { staticClass: "description" }, [
            _c(
              "div",
              { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "description-text",
                    staticStyle: { "margin-top": "13px" },
                  },
                  [
                    _c("div", { staticClass: "label" }, [_vm._v(" 产品型号 ")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(" QYCC-ACZ-7K "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "description-text",
                    staticStyle: { "margin-top": "13px" },
                  },
                  [
                    _c("div", { staticClass: "label" }, [_vm._v(" 防护等级 ")]),
                    _c("div", { staticClass: "value" }, [_vm._v(" IP54 ")]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 额定电压 ")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" AC220V / 50Hz "),
                ]),
              ]),
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 线缆长度 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 4m ")]),
              ]),
            ]),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 满载最大电流 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 32A ")]),
              ]),
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 信号网络 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 4G ")]),
              ]),
            ]),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 满载最大功率 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 7000W ")]),
              ]),
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 刷卡 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 支持 ")]),
              ]),
            ]),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 单口最大功率 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 7000W ")]),
              ]),
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 环境温度 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" -25~80℃ ")]),
              ]),
            ]),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 安装方式 ")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" 壁挂式 / 立柱式 "),
                ]),
              ]),
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 执行标准 ")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" GB/T20234、GB/T18487 "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "description-text" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 充电接口 ")]),
                _c("div", { staticClass: "value" }, [_vm._v(" 双枪 ")]),
              ]),
              _c("div", { staticClass: "description-text" }, [
                _c(
                  "div",
                  {
                    staticClass: "value",
                    staticStyle: { "white-space": "nowrap" },
                  },
                  [_vm._v(" GB/T27930、NB/T33008、NB/T33002 ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 外壳材质 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 阻燃ABS ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 主机尺寸 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 330*230*60mm ")]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "body-content", attrs: { id: "chdg" } }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 电动汽车直流充电桩 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "flex justify-content-between" }, [
        _c("div", { staticClass: "picture" }, [
          _c("img", {
            staticStyle: {
              width: "440px",
              height: "500px",
              display: "inline-block",
              "margin-left": "30px",
            },
            attrs: { src: require("@/assets/page/new-qy-01.png") },
          }),
        ]),
        _c("div", { staticClass: "description" }, [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
            [
              _c(
                "div",
                {
                  staticClass: "description-text",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 产品型号 ")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" QY01-Y750/160C "),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "description-text",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 功率因素 ")]),
                  _c("div", { staticClass: "value" }, [_vm._v(" ≥98% ")]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 额定电压 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 380V ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 线缆长度 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 5m ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 待机功率 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" ＜50W ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 外壳材质 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 钢板≥2mm ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 满载最大电流 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 250A ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 主机尺寸 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 750*565*1806mm ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 满载最大功率 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 160KW ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 环境温度 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" -25~80℃ ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 单口最大功率 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 80KW ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 执行标准 ")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(" GB/T20234、GB/T18487 "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 充电方式 ")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(" （动态分配）双枪均充 "),
              ]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c(
                "div",
                {
                  staticClass: "value",
                  staticStyle: { "white-space": "nowrap" },
                },
                [_vm._v(" GB/T27930、NB/T33008、NB/T33002 ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 恒功率电压范围 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" DC 300V-750V ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 效率 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" ≥95% ")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "body-content", attrs: { id: "hwyddy" } }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 户外移动电源 ")]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "flex justify-content-between" }, [
        _c("div", { staticClass: "picture" }, [
          _c("img", {
            staticStyle: {
              width: "400px",
              height: "266px",
              display: "inline-block",
              "margin-top": "117px",
              "margin-left": "50px",
            },
            attrs: { src: require("@/assets/page/hwdy.png") },
          }),
        ]),
        _c("div", { staticClass: "description" }, [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
            [
              _c(
                "div",
                {
                  staticClass: "description-text",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 产品型号 ")]),
                  _c("div", { staticClass: "value" }, [_vm._v(" PG740 ")]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "description-text",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 充满时长 ")]),
                  _c("div", { staticClass: "value" }, [_vm._v(" 约7小时 ")]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 输出功率 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 740V ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" USB输出 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 4口 ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 容量 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 20W ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 单口 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 5V/2.4A ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 电池类型 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 锂离子 ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 直流端口输出 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 12V/10A/120WAC ")]),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 控制器类型 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" MPPT ")]),
            ]),
            _c("div", { staticClass: "description-text" }, [
              _c("div", { staticClass: "label" }, [_vm._v(" 输出 ")]),
              _c("div", { staticClass: "value" }, [_vm._v(" 220V/50Hz ")]),
            ]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 逆变器类型 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 纯正弦波 ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 主机尺寸 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 270*172*220 ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 重量 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" 约5KG ")]),
          ]),
          _c("div", { staticClass: "description-text" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 环境温度 ")]),
            _c("div", { staticClass: "value" }, [_vm._v(" -10~40℃ ")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }