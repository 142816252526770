var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "title-bg" }, [
        _c("img", {
          staticStyle: {
            width: "428px",
            height: "66px",
            display: "inline-block",
            "margin-top": "87px",
          },
          attrs: { src: require("@/assets/page/about-qy-title.png") },
        }),
      ]),
      _c("div", { staticClass: "about-qy", attrs: { id: "qy-gsjj" } }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" 江西旗鱼物联技术有限公司 "),
        ]),
        _c("div", { staticClass: "english-title" }, [
          _vm._v(" JIANGXI SWORDFISH IOT TECHNOLOGY CO., LTD "),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "         由一支在智能充电领域深耕多年的团队发起，集研发、生产、运营为一体，是专业的智能充电服务综合解决"
          ),
          _c("br"),
          _vm._v(
            " 方案提供商，是一家拥有物联网+互联网基因的科技企业。公司具备大数据平台架构、软件、硬件的研发能力，"
          ),
          _c("br"),
          _vm._v(
            " 拥有完全自主知识产权的物联网管理平台与充电桩等相关技术，取得数十项发明专利，多项软件著作权。旗鱼充"
          ),
          _c("br"),
          _vm._v(
            " 充立足于南昌，辐射全国，依托强大的产品研发与运营服务能力，已在全国200多个城市完成市场布局，通过体"
          ),
          _c("br"),
          _vm._v(
            " 系化运营服务持续赋能合作伙伴，为深耕全国市场打下了坚实的基础。                                                                  "
          ),
        ]),
        _c("div", { staticClass: "green-text" }, [
          _vm._v(
            "          为用户提供一站式安全充电解决方案，进军社区、公共场所、市政合作等市场，打造以充电人群为入口的智"
          ),
          _c("br"),
          _vm._v(
            " 能生活生态服务平                                                                                                                                                      "
          ),
        ]),
      ]),
      _c("div", { staticClass: "qywh", attrs: { id: "qy-qywh" } }, [
        _c("div", { staticClass: "qywh-title" }, [
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 企业文化 ")]),
            _c("div", { staticClass: "line" }),
          ]),
          _c("div", [
            _c("img", {
              staticStyle: { width: "88px", height: "66px" },
              attrs: { src: require("@/assets/page/qywx.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "bodys" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "sm" }, [_vm._v(" 使命 ")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " 为亿万用户提供安全便捷的新能源服务成为世界新能源服务的引领者 "
              ),
            ]),
            _c("div", { staticClass: "yj" }, [_vm._v(" 愿景 ")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(" 以智慧能源驱动人类进步互联生活更美好 "),
            ]),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "jzg" }, [_vm._v(" 价值观 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 开放包容 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 成就客户 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 创新进取 ")]),
            _c("div", { staticClass: "text" }, [_vm._v(" 共同奋斗 ")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "zlzs", attrs: { id: "qy-zlzs" } }, [
        _c("div", { staticClass: "zlzs-title" }, [
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 专利证书 ")]),
            _c("div", { staticClass: "line" }),
          ]),
          _c("div", [
            _c("div", { staticClass: "more-btn" }, [
              _vm._v(" 查看全部 "),
              _c("img", {
                staticStyle: {
                  width: "18px",
                  height: "18px",
                  "margin-left": "20px",
                  "vertical-align": "-2px",
                },
                attrs: { src: require("@/assets/page/arrow-right.png") },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "bodys" }, [
          _c("div", [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/zlzs/fmzl.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [_vm._v(" 发明专利 ")]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/zlzs/syxxzl.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [_vm._v(" 实用新型专利 ")]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/zlzs/wgzl.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [_vm._v(" 外观设计专利 ")]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/zlzs/rjzzq.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [_vm._v(" 软件著作权 ")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "cjry", attrs: { id: "qy-cjry" } }, [
        _c("div", { staticClass: "cjry-title" }, [
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 成就荣誉 ")]),
            _c("div", { staticClass: "line" }),
          ]),
          _c("div", [
            _c("div", { staticClass: "more-btn" }, [
              _vm._v(" 查看全部 "),
              _c("img", {
                staticStyle: {
                  width: "18px",
                  height: "18px",
                  "margin-left": "20px",
                  "vertical-align": "-2px",
                },
                attrs: { src: require("@/assets/page/arrow-right.png") },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "bodys" }, [
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "150px",
                  height: "200px",
                  margin: "42px 0",
                },
                attrs: { src: require("@/assets/cjry/joj.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [
              _vm._v(" 2021年度中国新能源最具创新力品牌（金欧奖） "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "img", staticStyle: { "margin-left": "36px" } },
            [
              _c("div", [
                _c("img", {
                  staticStyle: {
                    width: "150px",
                    height: "200px",
                    margin: "42px 0",
                  },
                  attrs: { src: require("@/assets/cjry/xnycdzsj.png") },
                }),
              ]),
              _c("div", { staticClass: "zs-test" }, [
                _vm._v(" 2022年新能源充电桩十佳品牌 "),
              ]),
            ]
          ),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "320px",
                  height: "200px",
                  margin: "42px 0",
                },
                attrs: { src: require("@/assets/cjry/xnycdzsj1.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [
              _vm._v(" 2022年新能源充电桩十佳品牌 "),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/cjry/ljrw.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [
              _vm._v(" 2018品牌中国新能源产业领军人物 "),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/cjry/xcyppxr.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [
              _vm._v(" 2018中国新业态新模式新产业品牌新锐 "),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "200px", height: "284px" },
                attrs: { src: require("@/assets/cjry/jxpp.png") },
              }),
            ]),
            _c("div", { staticClass: "zs-test" }, [
              _vm._v(" 2018中国匠心品牌 "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "hzhb", attrs: { id: "qy-hzhb" } }, [
        _c("div", { staticClass: "hzhb-title" }, [
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 合作伙伴 ")]),
            _c("div", { staticClass: "line" }),
          ]),
          _c("div", [
            _c("div", { staticClass: "more-btn" }, [
              _vm._v(" 查看全部 "),
              _c("img", {
                staticStyle: {
                  width: "18px",
                  height: "18px",
                  "margin-left": "20px",
                  "vertical-align": "-2px",
                },
                attrs: { src: require("@/assets/page/arrow-right.png") },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "bodys" }, [
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "144px",
                  height: "144px",
                  margin: "18px 0",
                },
                attrs: { src: require("@/assets/page/fxwy.png") },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "img", staticStyle: { "margin-left": "60px" } },
            [
              _c("div", [
                _c("img", {
                  staticStyle: {
                    width: "240px",
                    height: "144px",
                    margin: "20px 0",
                  },
                  attrs: { src: require("@/assets/page/xl.png") },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "215px",
                  height: "170px",
                  margin: "5px 0",
                },
                attrs: { src: require("@/assets/page/fr.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "220px",
                  height: "63px",
                  margin: "58px 0",
                },
                attrs: { src: require("@/assets/page/zhwy.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "221px",
                  height: "160px",
                  margin: "10px 0",
                },
                attrs: { src: require("@/assets/page/bgy.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "280px",
                  height: "117px",
                  margin: "31px 0",
                },
                attrs: { src: require("@/assets/page/wkwy.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "187px",
                  height: "170px",
                  margin: "5px 0",
                },
                attrs: { src: require("@/assets/page/ldkg.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "276px", height: "180px" },
                attrs: { src: require("@/assets/page/csh.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "220px",
                  height: "43px",
                  margin: "68px 0",
                },
                attrs: { src: require("@/assets/page/xbcj.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "220px",
                  height: "55px",
                  margin: "63px 0",
                },
                attrs: { src: require("@/assets/page/blwy.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "183px", height: "180px" },
                attrs: { src: require("@/assets/page/zxwy.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "img" }, [
            _c("div", [
              _c("img", {
                staticStyle: {
                  width: "260px",
                  height: "113px",
                  margin: "33px 0",
                },
                attrs: { src: require("@/assets/page/ccwy.png") },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }