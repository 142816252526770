var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "title-tab", attrs: { id: "top" } }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "top-tab" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "首页", name: "home" },
                on: {
                  click: function ($event) {
                    _vm.activeName = "home"
                  },
                },
              }),
              _c("el-tab-pane", {
                attrs: { label: "关于旗鱼", name: "gyqy" },
                on: {
                  click: function ($event) {
                    _vm.activeName = "gyqy"
                  },
                },
              }),
              _c("el-tab-pane", {
                attrs: { label: "产品中心", name: "cpzx" },
                on: {
                  click: function ($event) {
                    _vm.activeName = "cpzx"
                  },
                },
              }),
              _c("el-tab-pane", {
                attrs: { label: "新闻资讯", name: "xwzx" },
                on: {
                  click: function ($event) {
                    _vm.activeName = "xwzx"
                  },
                },
              }),
              _c("el-tab-pane", {
                attrs: { label: "合作共赢", name: "hzgy" },
                on: {
                  click: function ($event) {
                    return _vm.changeTab()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm.activeName === "home"
          ? _c("home-page", { on: { aboutQy: _vm.goAbutQy } })
          : _vm._e(),
        _vm.activeName === "gyqy" ? _c("about-qy") : _vm._e(),
        _vm.activeName === "cpzx" ? _c("product-center") : _vm._e(),
        _vm.activeName === "xwzx" ? _c("news") : _vm._e(),
        _vm.activeName === "hzgy" ? _c("cooperation") : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 关于旗鱼 ")]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goAbutQy("qy-gsjj")
                  },
                },
              },
              [_vm._v(" 公司简介 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goAbutQy("qy-qywh")
                  },
                },
              },
              [_vm._v(" 企业文化 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goAbutQy("qy-zlzs")
                  },
                },
              },
              [_vm._v(" 专利证书 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goAbutQy("qy-cjry")
                  },
                },
              },
              [_vm._v(" 成就荣誉 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goAbutQy("qy-hzhb")
                  },
                },
              },
              [_vm._v(" 合作伙伴 ")]
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 产品中心 ")]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goProductCenter("dpccdz")
                  },
                },
              },
              [_vm._v(" 电瓶车充电桩 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goProductCenter("ddqccdz")
                  },
                },
              },
              [_vm._v(" 电动汽车充电桩 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goProductCenter("chdg")
                  },
                },
              },
              [_vm._v(" 充/换电柜 ")]
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 合作共赢 ")]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZGY("scqj")
                  },
                },
              },
              [_vm._v(" 市场前景 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZGY("qyys")
                  },
                },
              },
              [_vm._v(" 旗鱼优势 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZGY("hzbz")
                  },
                },
              },
              [_vm._v(" 合作保障 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZGY("hzlc")
                  },
                },
              },
              [_vm._v(" 合作流程 ")]
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(" 合作方式 ")]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZFS("dls")
                  },
                },
              },
              [_vm._v(" 成为代理商 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZFS("hhr")
                  },
                },
              },
              [_vm._v(" 成为合伙人 ")]
            ),
            _c(
              "div",
              {
                staticClass: "text",
                on: {
                  click: function ($event) {
                    return _vm.goHZFS("jrqy")
                  },
                },
              },
              [_vm._v(" 加入旗鱼 ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "vertical-line" }),
        _vm._m(1),
      ]),
      _c("div", { staticClass: "phone" }, [
        _c("div", { staticStyle: { flex: "3" } }, [
          _c(
            "div",
            [
              _c("svg-icon", {
                staticStyle: { width: "40px", height: "40px" },
                attrs: { name: "smile", original: "" },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "font-size": "20px",
                    color: "#FFFFFF",
                    "vertical-align": "10px",
                    "white-space": "nowrap",
                  },
                },
                [_vm._v(" 服务热线 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                "font-size": "30px",
                color: "#FFFFFF",
                "white-space": "nowrap",
              },
            },
            [_vm._v(" 400-1688-951 ")]
          ),
        ]),
        _c("div", { staticStyle: { flex: "3", "margin-left": "10px" } }, [
          _c(
            "div",
            [
              _c("svg-icon", {
                staticStyle: { width: "40px", height: "40px" },
                attrs: { name: "email", original: "" },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "font-size": "20px",
                    color: "#FFFFFF",
                    "vertical-align": "10px",
                  },
                },
                [_vm._v(" 电子邮箱 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                "font-size": "30px",
                color: "#FFFFFF",
                "white-space": "nowrap",
              },
            },
            [_vm._v(" 401757384@qq.com ")]
          ),
        ]),
        _c("div", { staticStyle: { flex: "4", "margin-left": "10px" } }, [
          _c(
            "div",
            [
              _c("svg-icon", {
                staticStyle: { width: "40px", height: "40px" },
                attrs: { name: "adress", original: "" },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "font-size": "20px",
                    color: "#FFFFFF",
                    "vertical-align": "10px",
                  },
                },
                [_vm._v(" 公司地址 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                "font-size": "30px",
                color: "#FFFFFF",
                "white-space": "nowrap",
              },
            },
            [_vm._v(" 江西省南昌市红谷滩华兴文化广场1号楼1301 ")]
          ),
        ]),
      ]),
      _vm._m(2),
    ]),
    _c("div", { staticClass: "fixed-btn", on: { click: _vm.toTop } }, [
      _c(
        "div",
        { staticClass: "btns" },
        [
          _c("svg-icon", {
            staticStyle: { width: "26px", height: "26px" },
            attrs: { name: "top", original: "" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("img", {
        staticStyle: {
          width: "132px",
          height: "50px",
          display: "inline-block",
          "margin-left": "176px",
        },
        attrs: { src: require("@/assets/page/qy-logo.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "right" }, [
      _c("img", {
        staticStyle: { width: "200px", height: "200px" },
        attrs: { src: require("@/assets/page/wxgzh.png") },
      }),
      _c("img", {
        staticStyle: { width: "200px", height: "200px", "margin-left": "60px" },
        attrs: { src: require("@/assets/page/wxxcx.png") },
      }),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "200px",
              "text-align": "center",
              "margin-top": "10px",
              "font-size": "20px",
              "font-weight": "400",
              color: "#FFFFFF",
            },
          },
          [_vm._v(" 微信公众号 ")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "200px",
              "text-align": "center",
              "margin-top": "10px",
              "font-size": "20px",
              "font-weight": "400",
              color: "#FFFFFF",
              "margin-left": "60px",
            },
          },
          [_vm._v(" 微信小程序 ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticStyle: { "text-align": "right", "margin-top": "40px" } },
      [
        _c("span", { staticStyle: { color: "#FFFFFF", "font-size": "20px" } }, [
          _vm._v(" 版权所有 "),
        ]),
        _c(
          "span",
          {
            staticStyle: {
              color: "#FFFFFF",
              "font-size": "20px",
              "margin-left": "10px",
            },
          },
          [_vm._v(" ©2020 江西旗鱼物联技术有限公司 ")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              color: "#FFFFFF",
              "font-size": "20px",
              "margin-left": "100px",
            },
          },
          [_vm._v(" 赣ICP备14056214号-3 ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }